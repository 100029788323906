<template>
  <v-card width="80%" class="mx-auto">
    <v-card-text>
      <v-form dense class="mt-3 px-3">
        <v-row>
          <v-col cols="6" 
            class="py-0"
            v-for="(textField, index) in storeAddress" 
            :key="index">
            <v-text-field
              v-model="model[textField.model].value"
              :label="textField.label"
              :required="model[textField.model].required"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      model: {
        type: Object,
      },
    },
    data() {
      return {
        
        storeAddress: [
          {
            model: "addy1",
            label: "Address Line 1*"
          },
          {
            model: "addy2",
            label: "Address Line 2*"
          },
          {
            model: "suburb",
            label: "Suburb*"
          },
          {
            model: "city",
            label: "City*"
          },
          {
            model: "country",
            label: "Country*"
          },
          {
            model: "postcode",
            label: "Postcode*"
          },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>