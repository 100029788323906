<template>
  <div>
    <v-container>
      <base-loader :isLoading="isLoading"></base-loader>
    </v-container>
    <!-- Payfast form https://sandbox.payfast.co.za/eng/process-->
    <form
      ref="payfastForm"
      action="https://www.payfast.co.za/eng/process"
      method="POST"
    >
      <!-- Merchant Details -->
      <input
        type="hidden"
        name="merchant_id"
        v-model="payfastForm.merchant_id"
      />
      <input
        type="hidden"
        name="merchant_key"
        v-model="payfastForm.merchant_key"
      />
      <input type="hidden" name="return_url" v-model="payfastForm.return_url" />
      <input type="hidden" name="cancel_url" v-model="payfastForm.cancel_url" />
      <input type="hidden" name="notify_url" v-model="payfastForm.notify_url" />

      <!-- Buyer Details -->
      <input type="hidden" name="name_first" v-model="payfastForm.name_first" />
      <input type="hidden" name="name_last" v-model="payfastForm.name_last" />
      <input
        type="hidden"
        name="email_address"
        v-model="payfastForm.email_address"
      />
      <input
        type="hidden"
        name="cell_number"
        v-model="payfastForm.cell_number"
      />

      <!-- Transaction Details -->
      <input
        type="hidden"
        name="m_payment_id"
        v-model="payfastForm.m_payment_id"
      />
      <input type="hidden" name="amount" v-model="payfastForm.amount" />
      <input type="hidden" name="item_name" v-model="payfastForm.item_name" />
      <!-- <input type="hidden" name="item_description" v-model="payfastForm.item_description"> -->

      <!-- Payment Method -->
      <input
        type="hidden"
        name="payment_method"
        v-model="payfastForm.payment_method"
      />
      <input
        type="hidden"
        name="signature"
        v-model="payfastForm.MD5Signature"
      />
    </form>
    <section-banner :model="section"></section-banner>
    <!-- Checkout Steps -->
    <v-stepper :value="step + 1">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="step > 0"
          >Login/Signup</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="2" :complete="step > 1"
          >Checkout Options</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="3" :complete="step > 2"
          >Billing Details</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="4">Confirm Order</v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <v-window v-model="step">
      <v-window-item v-for="(item, index) in steps" :key="index" :value="index">
        <v-card class="d-flex flex-column">
          <v-card-text>
            <component :is="item.component" :model="item.formItems"></component>
          </v-card-text>

          <v-spacer></v-spacer>

          <v-card-actions class="mx-auto mb-3">
            <v-btn @click="step--" v-if="index > 1">Prev</v-btn>
            <v-btn
              @click="step++"
              v-if="
                (index == 1 &&
                  item.formItems.name_first.value &&
                  item.formItems.name_last.value &&
                  item.formItems.email_address.value &&
                  item.formItems.cell_number.value &&
                  item.formItems.terms.value &&
                  item.formItems.acknowledge.value) ||
                  index == 2
              "
              >Next</v-btn
            >
            <v-btn @click="pay()" color="success" v-if="index == 3">Pay</v-btn>
          </v-card-actions>
        </v-card>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";
import CheckoutOptions from "@/components/Frontend/Commerce/Checkout/CheckoutOptions";
import BillingDetails from "@/components/Frontend/Commerce/Checkout/BillingDetails";
import ConfirmOrder from "@/components/Frontend/Commerce/Checkout/ConfirmOrder";
import Login from "@/views/Frontend/User/Login";
import md5 from "md5";
import { gql } from "apollo-boost";
// import { mapActions, mapState } from 'vuex';

export default {
  components: {
    CheckoutOptions,
    BillingDetails,
    ConfirmOrder,
    SectionBanner,
  },
  name: "Checkout",
  data() {
    return {
      isLoading: false,
      step: 0,
      me: "",
      cartTotal: 0,
      currentCart: {},
      payfastForm: {
        merchant_id: "",
        merchant_key: "",
        return_url: "",
        cancel_url: "",
        notify_url: "",
        name_first: "",
        name_last: "",
        email_address: "",
        cell_number: "",
        m_payment_id: "",
        amount: "",
        item_name: "",
        item_description: "",
        payment_method: "",
        passphrase: "",
        MD5Signature: "",
      },
      steps: [
        {
          action: "login",
          title: "Login",
          active: true,
          component: Login,
        },
        {
          action: "checkout_options",
          title: "Checkout Options",
          active: false,
          component: CheckoutOptions,
          formItems: {
            name_first: {
              value: "",
              required: true,
            },
            name_last: {
              value: "",
              required: true,
            },
            email_address: {
              value: "",
              required: true,
            },
            cell_number: {
              value: "",
              required: true,
            },
            terms: {
              value: false,
              required: true,
            },
            acknowledge: {
              value: false,
              required: true,
            },
            payment_method: {
              value: "cc",
              required: true,
            },
          },
        },
        {
          action: "billing_details",
          title: "Billing Details",
          active: false,
          component: BillingDetails,
          formItems: {
            addy1: {
              value: "",
              required: true,
            },
            addy2: {
              value: "",
              required: false,
            },
            suburb: {
              value: "",
              required: true,
            },
            city: {
              value: "",
              required: true,
            },
            country: {
              value: "",
              required: true,
            },
            postcode: {
              value: "",
              required: true,
            },
          },
        },
        {
          action: "confirm_order",
          title: "Confirm Order",
          active: false,
          component: ConfirmOrder,
        },
      ],
      section: {
        BannerSmall: true,
        Title: "Checkout",
        Subtitle: "One step closer to boutique legal solutions",
        Description:
          "Complete the checkout steps below to finalise and place your order.",
        img: require("@/assets/img/background.jpg"),
      },
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
            email
            telNumber
            location
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.step;
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      result(response) {
        if (response) {
          this.steps[this.step].formItems["name_first"].value =
            response.data.me.firstName;
          this.steps[this.step].formItems["name_last"].value =
            response.data.me.lastName;
          this.steps[this.step].formItems["email_address"].value =
            response.data.me.email;
          this.steps[this.step].formItems["cell_number"].value =
            response.data.me.telNumber;
          if (response && response?.data?.me?.location) {
            this.steps[this.step + 1].formItems["addy1"].value =
              response?.data?.me?.location?.addy1 || "";
            this.steps[this.step + 1].formItems["addy2"].value =
              response?.data?.me?.location?.addy2;
            this.steps[this.step + 1].formItems["suburb"].value =
              response?.data?.me?.location?.suburb;
            this.steps[this.step + 1].formItems["city"].value =
              response?.data?.me?.location?.city;
            this.steps[this.step + 1].formItems["country"].value =
              response?.data?.me?.location?.country;
            this.steps[this.step + 1].formItems["postcode"].value =
              response?.data?.me?.location?.postcode;
          }
        }
      },
    },
    settings: {
      query: gql`
        query {
          settings {
            payfast {
              merchantID
              merchantKey
              passPhrase
              returnURL
              cancelURL
              notifyURL
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return false;
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      result(response) {
        const self = this;
        if (response) {
          self.payfastForm.merchant_id =
            response.data.settings.payfast.merchantID; //response.data.settings.payfast.merchantID //process.env.APP_PAYFAST_MERCHANT_ID
          self.payfastForm.merchant_key =
            response.data.settings.payfast.merchantKey; // response.data.settings.payfast.merchantKey //process.env.APP_PAYFAST_MERCHANT_KEY
          self.payfastForm.passphrase =
            response.data.settings.payfast.passPhrase; // response.data.settings.payfast.passphrase //process.env.APP_PAYFAST_PASS_PHRASE
          self.payfastForm.return_url =
            response.data.settings.payfast.returnURL +
            self.payfastForm.m_payment_id; // `https://www.nlateam.com/commerce/success/${self.payfastForm.m_payment_id}`
          self.payfastForm.cancel_url =
            response.data.settings.payfast.cancelURL; //"https://www.nlateam.com/commerce/cancel"
          self.payfastForm.notify_url =
            response.data.settings.payfast.notifyURL;
        }
      },
    },
  },
  created() {
    this.$eventHub.$on("logged-in", this.loggedIn);
    this.$eventHub.$on("confirmCart", this.confirmCart);

    this.step = 1;
    if (!localStorage.getItem("token")) this.step = 0;
  },
  watch: {
    currentCart: function(newValue, oldValue) {
      if (newValue && newValue.id) {
        this.payfastForm.m_payment_id = this.currentCart.id;
        this.payfastForm.return_url =
          this.settings.payfast.returnURL + this.currentCart.id; //make sure this thing is set
        this.payfastForm.item_name = this.currentCart.id;
      }
    },
    cartTotal: function(newValue, oldValue) {
      if (newValue) {
        this.payfastForm.amount = this.cartTotal;
      }
    },
  },
  // beforeDestroy() {
  //   this.$eventHub.$off("logged-in");
  //   this.$eventHub.$off("confirmCart");
  // },
  updated() {
    if (this.step == 2) {
      const keys = Object.keys(this.steps[this.step - 1].formItems);
      keys.forEach((item, i) => {
        this.payfastForm[item] = this.steps[this.step - 1].formItems[
          item
        ].value;
      });
    }

    if (this.step == 3) {
      // dont need delivery details on payfast form
    }
  },
  methods: {
    loggedIn() {
      this.step++;
      this.$apollo.queries.me.refetch();
    },

    confirmCart(data) {
      this.cartTotal = data.total;
      this.currentCart = data.cart;
    },

    encodeSignature() {
      return new Promise((resolve, reject) => {
        const params = {
          merchant_id: this.payfastForm.merchant_id,
          merchant_key: this.payfastForm.merchant_key,
          return_url: this.payfastForm.return_url,
          cancel_url: this.payfastForm.cancel_url,
          notify_url: this.payfastForm.notify_url,
          name_first: this.payfastForm.name_first,
          name_last: this.payfastForm.name_last,
          email_address: this.payfastForm.email_address,
          cell_number: this.payfastForm.cell_number,
          m_payment_id: this.payfastForm.m_payment_id,
          amount: this.payfastForm.amount,
          item_name: this.payfastForm.item_name,
          payment_method: this.payfastForm.payment_method,
          passphrase: this.payfastForm.passphrase,
        };

        console.log("params", JSON.stringify(params));

        var esc = encodeURIComponent;
        var query = Object.keys(params)
          .map(
            (key) =>
              `${key}=${encodeURIComponent(
                params[key].toString().trim()
              ).replace(/%20/g, "+")}`
          )
          .join("&");
        console.log("query", query);
        this.payfastForm.MD5Signature = md5(query);
        resolve("encodedPayfastDone");
      });
    },
    async pay() {
      const self = this;
      self.isLoading = true;
      self.payfastForm.amount = self.cartTotal; // todo this needs to come from order
      self.payfastForm.m_payment_id = self.currentCart.id;
      self.payfastForm.item_name = self.payfastForm.m_payment_id;
      var isDoneEncoding = await self.encodeSignature();
      console.log(self.payfastForm);
      console.log("isDoneEncoding", isDoneEncoding);
      self.isLoading = false;
      await self.submitPayment();
    },
    async submitPayment() {
      const self = this;
      this.isLoading = true;
      setTimeout(() => {
        // not passing any argument, resolve is defined in the upper scope.
        self.$nextTick(() => {
          console.log("self.payfastForm", JSON.stringify(self.payfastForm));
          console.log(
            "self.$refs.payfastForm",
            JSON.stringify(self.$refs.payfastForm)
          );
          self.$refs.payfastForm.submit();
        });
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
