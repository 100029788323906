<template>
  <v-card width="80%" class="mx-auto">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="cartRows"
        :items-per-page="5"
        hide-default-footer
        class="elevation-0 mt-3"
      >
        <template v-slot:item.price="{ item }">
          <h5>
            {{
              item.price
                | currency("R", 2, {
                  thousandsSeparator: ",",
                  decimalSeparator: ".",
                })
            }}
          </h5>
        </template>
        <template v-slot:item.amount="{ item }">
          <h5>
            {{
              item.amount
                | currency("R", 2, {
                  thousandsSeparator: ",",
                  decimalSeparator: ".",
                })
            }}
          </h5>
        </template>
        <template v-slot:item.bin="{ item }">
          <v-icon @click="removeProductFromCart(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <hr />
      <v-row class="mt-2 mr-0">
        <v-col cols="8"></v-col>
        <v-col cols="4" style="background-color:#e6e6e6;">
          <v-data-table
            :headers="summary"
            :items="summaryRows"
            hide-default-header
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item.amount="{ item }">
              <h5>
                {{
                  item.amount
                    | currency("R", 2, {
                      thousandsSeparator: ",",
                      decimalSeparator: ".",
                    })
                }}
              </h5>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      skipQuery: true,

      totalItems: 0,

      cart: {},
      total: 0,
      products: [],
      cartRows: [],
      summaryRows: [],

      headers: [
        { text: "Item", align: "start", sortable: false, value: "item" },
        { text: "Qty", value: "qty", align: "start" },
        { text: "Item Price", value: "price", align: "start" },
        { text: "Total", value: "amount", align: "start" },
        { text: "Delete", value: "bin", sortable: false, align: "start" },
      ],

      summary: [
        { text: "summary", align: "start", sortable: false, value: "summary" },
        { text: "amount", align: "right", sortable: false, value: "amount" },
      ],
    };
  },
  apollo: {
    orders: {
      query: gql`
        query Orders($limit: Int!, $skip: Int!, $query: JSON!) {
          orders(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              totalAmount
              products
              createdAt
            }
          }
        }
      `,
      skip() {
        return this.skipQuery;
      },
      variables() {
        const self = this;
        return {
          limit: 1,
          skip: 0,
          query: {
            createdBy: self.me.id,
          },
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.cart = this.orders.records[0];
        this.products = this.cart.products;
        this.totalItems = this.products.length;

        const grouped = _.groupBy(this.products, "id");
        const entries = Object.entries(grouped);

        this.cartRows = [];
        for (const [product_id, value] of entries) {
          this.cartRows.push({
            id: value[0].id,
            item: value[0].title,
            qty: value.length,
            price: value[0].price,
            amount: value.length * value[0].price,
          });
        }

        this.summaryRows = [];
        const total = _.map(this.cartRows, (item) => {
          return item.qty * item.price;
        }).reduce((a, b) => a + b);

        console.log("Submitting cart update");
        this.total = total;
        this.$eventHub.$emit("confirmCart", {
          cart: this.cart,
          total: this.total,
        });

        this.summaryRows.push({
          summary: "Total Excl Vat",
          amount: total / 1.15,
        });
        this.summaryRows.push({
          summary: "Vat",
          amount: (total / 1.15) * 0.15,
        });
        this.summaryRows.push({
          summary: "Total Incl Vat",
          amount: total,
        });
      },
    },
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
      fetchPolicy: "network-only",
      result() {
        this.$apollo.queries.orders.skip = false;
        this.$apollo.queries.orders.refetch();
      },
    },
  },
  watch: {
    cart: function(newValue, oldValue) {
      console.log("Submitting cart update cart watch");
      this.$eventHub.$emit("confirmCart", {
        cart: this.cart,
        total: this.total,
      });
    },
    total: function(newValue, oldValue) {
      console.log("Submitting cart update total watch");
      this.$eventHub.$emit("confirmCart", {
        cart: this.cart,
        total: this.total,
      });
    },
  },
  methods: {
    async removeProductFromCart(product) {
      console.log(product);
      const self = this;
      const response = await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!, $product: ID!) {
            removeProductFromOrder(id: $id, product: $product) {
              id
              updated
            }
          }
        `,
        variables: {
          id: self.cart.id,
          product: product.id,
        },
      });
      console.log(response);
      this.$apollo.queries.orders.skip = false;
      this.$apollo.queries.orders.refetch();
    },
  },
};
</script>

<style lang="scss" scoped></style>
