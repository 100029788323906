<template>
  <div>
    <v-card width="80%" class="mx-auto">
      <v-card-text>
        <v-form dense class="mt-3 px-3">
          <v-row>
            <v-col
              cols="6"
              class="py-0"
              v-for="(textField, index) in userDetails"
              :key="index"
            >
              <v-text-field
                v-model="model[textField.model].value"
                :label="textField.label"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-md-6">
              <v-radio-group v-model="model.payment_method.value">
                <template v-slot:label>
                  <div>Your <strong>Payment Method</strong></div>
                </template>
                <v-radio value="cc" class="checkout__radio">
                  <template v-slot:label>
                    <div>
                      <strong class="primary__text">Credit Card</strong>
                    </div>
                  </template>
                </v-radio>
                <!-- <v-radio value="EFT" class="checkout__radio">
                  <template v-slot:label>
                    <div><strong class="primary__text">EFT</strong></div>
                  </template>
                </v-radio> -->
              </v-radio-group>
            </v-col>
            <v-col class="col-md-6">
              <v-checkbox v-model="model.terms.value" class="checkout__box">
                <template v-slot:label>
                  <div>
                    <strong class="primary__text">
                      I agree to the
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="/documents/NLA-Legal-E-Commerce-Terms-Conditions.pdf"
                            @click.stop
                            v-on="on"
                          >
                            Terms and Conditions
                          </a>
                        </template>
                        View the Terms and Conditions Here
                      </v-tooltip>
                    </strong>
                  </div>
                </template>
                <!-- <template v-slot:label>
                  <div>
                    <strong class="primary__text"
                      >I agree to the
                      <a
                        href="/documents/NLA-Legal-E-Commerce-Terms-Conditions.pdf"
                        target="_blank"
                        >Terms &amp; Conditions.</a
                      ></strong
                    >
                  </div>
                </template> -->
              </v-checkbox>
              <v-checkbox class="checkout__box">
                <template v-slot:label>
                  <div>
                    <strong class="primary__text"
                      >I consent to receiving marketing material (including
                      electronic communications) relating to other legal and/or
                      advisory services and/or products from NLA that I may find
                      interesting</strong
                    >
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                v-model="model.acknowledge.value"
                class="checkout__box"
              >
                <template v-slot:label>
                  <div>
                    <strong class="primary__text"
                      >I acknowledge that NLA owns the intellectual property
                      relating to the documents purchased, and that no unlawful
                      or unauthorised reproduction thereof is permitted</strong
                    >
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      userDetails: [
        {
          model: "name_first",
          label: "Name*",
        },
        {
          model: "name_last",
          label: "Surname*",
        },
        {
          model: "email_address",
          label: "Email*",
        },
        {
          model: "cell_number",
          label: "Mobile*",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
